.space-evenly {
    display: flex;
    justify-content: space-evenly;
}

.order-detail h5 {
    width: 100%;
    color: #767b85;
    font-size: 15px;
    padding-left: 3%;
}

/* h1 {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    background: #fff;
    padding: 10px 15px;
    border-bottom: 2px solid #0082ab69;
    font-size: 20px !important;

} */