.react-tabs__tab--selected {
    background: #fff;
    border-color: #dbd5d5;
    color: black;
    border-radius: 0;
}

.react-tabs__tab-list {
    border-bottom: 1px solid #e1e0e0;
    margin: 0 0 10px;
    padding: 0;
}
.lowest-pric
{
    font-size: 14px;
line-height: 1.0;
text-align: center;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}
.update-td
{
    display: flex;
flex-direction: column;
align-items: center;
gap: 7px;
justify-content: center;
}
.table-bordered td, .table-bordered th {
 
    vertical-align: middle;
  }